import React from 'react';

import { AngleRightFat } from '../../_common/Icons';
import IconLinks from '../../footer/IconLinks';
import Link from '../../Link';
import MediaSlice from '../../slices/Media';

const ProstaffListItem = ({ prostaffer, site }) => {
  return (
    <li className="prostaff-item">
      <div className="prostaff-item__featured-media">
        <Link to={prostaffer} site={site}>
          <h3 className="prostaff-item__title">{prostaffer.name}</h3>
        </Link>

        <Link to={prostaffer} site={site}>
          <MediaSlice media={prostaffer.featuredMedia} />
        </Link>

        <div className="prostaff-item__footer">
          <Link to={prostaffer} site={site}>
            Read bio {AngleRightFat}
          </Link>
          <IconLinks links={prostaffer.socialLinks} />
        </div>
      </div>
    </li>
  );
};

export default ProstaffListItem;

import React from 'react';

import ProstaffListItem from './ProstaffListItem';

const ProstaffList = ({ prostaff, site }) => {
  return (
    <ul className="prostaff-list">
      {prostaff.map((prostaffer) => (
        <ProstaffListItem
          key={prostaffer.id}
          prostaffer={prostaffer}
          site={site}
        />
      ))}
    </ul>
  );
};

export default ProstaffList;

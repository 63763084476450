import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { AngleRightFat } from '../../_common/Icons';
import IconLinks from '../../footer/IconLinks';
import Link from '../../Link';
import MediaSlice from '../../slices/Media';

const ProstaffMapMarker = ({ Marker, Popup, divIcon, prostaffer, site }) => {
  const markerIcon = (
    <svg
      viewBox="0 0 10 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-640.000000, -201.000000)">
        <path d="M645,215 C645,215 640,208.999505 640,206.153375 C640,203.307244 642.238576,201 645,201 C647.761424,201 650,203.307244 650,206.153375 C650,208.999505 645,215 645,215 Z M645,208 C646.104569,208 647,207.104569 647,206 C647,204.895431 646.104569,204 645,204 C643.895431,204 643,204.895431 643,206 C643,207.104569 643.895431,208 645,208 Z" />
      </g>
    </svg>
  );

  const popupContent = (
    <div className="prostaff-item">
      <div className="prostaff-item__featured-media">
        <Link to={prostaffer} site={site}>
          <MediaSlice media={prostaffer.featuredMedia} />
          <h3 className="prostaff-item__title">{prostaffer.name}</h3>
        </Link>
      </div>
    </div>
  );

  return (
    <Marker
      key={prostaffer.id}
      icon={divIcon({
        iconSize: [24, 32],
        iconAnchor: [0, 32],
        popupAnchor: [12, -32],
        html: renderToStaticMarkup(markerIcon),
      })}
      id={prostaffer.id}
      position={[prostaffer.lat, prostaffer.lng]}
    >
      <Popup>{popupContent}</Popup>
    </Marker>
  );
};

export default ProstaffMapMarker;

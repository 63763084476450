import React, { Component } from 'react';
import { graphql } from 'gatsby';

import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import ProstaffComponent from '../components/prostaff/prostaff/Prostaff';
import ProstafferModel from '../models/Prostaffer';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query ($prostaffIds: [String]) {
    allSanityProstaffer(filter: { _id: { in: $prostaffIds } }) {
      edges {
        node {
          _id
          bio {
            _rawLanguagePrimary
            _rawLanguageSecondary
          }
          featuredMedia {
            image {
              asset {
                gatsbyImageData
              }
            }
            type
            video {
              imageUrl
              value
            }
            videoPickit {
              imageUrl
              value
            }
            videoType
            videoYoutube
          }
          id
          location {
            lat
            lng
          }
          name
          social {
            type
            url
          }
          targetWaters
          uid {
            current
          }
        }
      }
    }
  }
`;

class Prostaff extends Component {
  render() {
    const { brand, path, site } = this.props;
    const { siteUID } = this.props.pageContext;
    const { allSanityProstaffer } = this.props.data;

    const prostaff = mapEdgesToNodes(allSanityProstaffer).map(
      (prostaffer) => new ProstafferModel(prostaffer, siteUID)
    );

    return (
      <ProstaffComponent
        breadcrumbs={breadcrumbs('OUR PROSTAFF', prostaff)}
        jsonLd={jsonLd(brand, site, path, prostaff)}
        prostaff={prostaff}
        site={site}
      />
    );
  }
}

export default Prostaff;

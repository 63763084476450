import Helmet from 'react-helmet';
import React, { useState } from 'react';

import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import JsonLD from '../../_common/JsonLD';
import ProstaffList from './ProstaffList';
import ProstaffMap from './ProstaffMap';
import Select from '../../_common/Select';

const Prostaff = ({ breadcrumbs, jsonLd, prostaff, site }) => {
  const [view, setView] = useState('List');
  const [targetWaters, setTargetWaters] = useState('All');

  const onViewChange = (value) => {
    setView(value.value);
  };

  const onTargetWatersChange = (e) => {
    setTargetWaters(e.target.value);
  };

  const targetWatersRadioComponent = (value) => (
    <span className="prostaff-filters__option">
      <input
        type="radio"
        id={value}
        name="target-waters"
        value={value}
        onChange={onTargetWatersChange}
        checked={targetWaters === value}
      />
      <label htmlFor={value}>{value}</label>
    </span>
  );

  const filteredProstaff =
    targetWaters !== 'All'
      ? prostaff.filter((prostaffer) =>
          prostaffer.targetWaters.includes(targetWaters)
        )
      : prostaff;

  return (
    <article className="page article prostaff">
      <Helmet title={`Our Prostaff`} />
      <JsonLD jsonLd={jsonLd} />
      <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

      <header className="article__header">
        <div className="article__featured">
          <div className="article__featured-text">
            <h1 className="article__title">OUR PROSTAFF</h1>
          </div>
        </div>
      </header>

      <div className="article__section">
        <div className="prostaff-header">
          <div className="prostaff-filters">
            {targetWatersRadioComponent('All')}
            {targetWatersRadioComponent('Saltwater')}
            {targetWatersRadioComponent('Freshwater')}
          </div>

          <div className="prostaff-views">
            <Select
              options={[
                { label: 'List', value: 'List' },
                { label: 'Map', value: 'Map' },
              ]}
              value={{
                label: view,
                value: view,
              }}
              onChange={onViewChange}
            />
          </div>
        </div>

        {view === 'List' ? (
          <ProstaffList prostaff={filteredProstaff} site={site} />
        ) : (
          <ProstaffMap prostaff={filteredProstaff} site={site} />
        )}
      </div>
    </article>
  );
};

export default Prostaff;

import React, { useEffect, useState } from 'react';

import ProstaffMapMarker from './ProstaffMapMarker';

let Map, TileLayer, Marker, Popup, divIcon;

const ProstaffMap = ({ prostaff, site }) => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [viewport, setViewport] = useState({
    center: [0, 0],
    zoom: 2,
  });

  useEffect(() => {
    Map = require('react-leaflet').Map;
    TileLayer = require('react-leaflet').TileLayer;
    Marker = require('react-leaflet').Marker;
    Popup = require('react-leaflet').Popup;
    divIcon = require('leaflet').divIcon;
    setFirstLoad(true);
  }, []);

  const onViewportChanged = (v) => {
    if (v.x !== viewport.x || v.y !== viewport.y || v.zoom !== viewport.zoom) {
      setViewport(v);
    }
  };

  if (!firstLoad || typeof window === 'undefined') return null;

  return (
    <div className="prostaff-map">
      <Map onViewportChanged={onViewportChanged} viewport={viewport}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        {prostaff.map((prostaffer) => (
          <ProstaffMapMarker
            divIcon={divIcon}
            Marker={Marker}
            Popup={Popup}
            prostaffer={prostaffer}
            site={site}
          />
        ))}
      </Map>
    </div>
  );
};

export default ProstaffMap;
